html, body {
  margin: 0;
  padding: 0;
  font-family: 'Teko', sans-serif;
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px;
  }
}